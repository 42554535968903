import { GetSubscribersEnhancer } from "./get-subscribers-types";
import { isSideLabelSkinGS } from "./get-subscribers-utils";
import { FIELDS_ROLES } from "../../../../constants/roles";
import _ from 'lodash';
import sideInputLabel from '../../../../assets/presets/side-input-label.json'

const fixSideLabelSkin: GetSubscribersEnhancer = (
  settings,
  convertedForm,
) => {
  if (!isSideLabelSkinGS(settings)) {
    return convertedForm
  }
  return {
    ...convertedForm,
    components: convertedForm.components.reduce((currComponents, component) => {
      if (_.includes(FIELDS_ROLES, component.role)) {
        const labelText = component.data.placeholder
        const inputComponent = _.merge({}, component, {
          data: { placeholder: '' },
          props: { placeholder: '' },
        })
        const labelComponent = _.merge({}, sideInputLabel, {
          data: {
            text: `<p class="font_8"><span style="color:#000000;">${labelText}</span></p>`,
          },
        })
        return [...currComponents, labelComponent, inputComponent]
      } else {
        return [...currComponents, component]
      }
    }, []),
  }
}

const emailEnhancer:GetSubscribersEnhancer  = (
  settings,
  structure,
) => {
  const supportNotifications = settings.generalSettings.subscriptionNotifications
  const emails = supportNotifications ? [settings.email].filter((email) => email) : []

  return {
    ...structure,
    config: {
      ...structure.config,
      emailIds: emails
    },
  }
}

export const formEnhancers: GetSubscribersEnhancer[] = [
  fixSideLabelSkin,
  emailEnhancer,
]