import _ from 'lodash'
import { isInputBorderBottom } from './get-subscribers-utils'

export enum GSLabels {
  Title = 'Title',
  Subtitle = 'Subtitle',
  ThankYouTitle = 'ThankYouTitle',
  ThankYouSubtitle = 'ThankYouSubtitle',
}

const defaultsByStyle = [
  {
    container: {
      brw: 3,
    },
    input: {
      brw: `0 0 1px 0`,
    },
  },
  {
    container: {
      brw: 3,
      rd: 0,
    },
    input: {},
  },
  {
    container: {},
    input: {
      brw: `0 0 1px 0`,
    },
  },
  {
    container: {},
    input: {},
  },
  {
    container: {},
    input: {},
  },
]

const fontAndColor = (style, fontKey, colorKey, lineHeight, align?) => {
  const font = JSON.parse(style.style.properties[fontKey] || '{}') //TODO add default
  const color = style.style.properties[colorKey]
  return { color, font, lineHeight, align }
}

const styles = (style, label: GSLabels) => {
  switch (label) {
    case GSLabels.Title:
      return fontAndColor(style, 'param_font_titleFont', 'param_color_titleColor', '1.3em')
    case GSLabels.Subtitle:
      return fontAndColor(style, 'param_font_subtitleFont', 'param_color_subtitleColor', '1.3em')
    case GSLabels.ThankYouTitle:
      return fontAndColor(style, 'param_font_titleFont', 'param_color_titleColor', null, 'center')
    case GSLabels.ThankYouSubtitle:
      return fontAndColor(
        style,
        'param_font_subtitleFont',
        'param_color_titleColor',
        null,
        'center',
      )
    default:
      return {
        color: null,
        font: null,
        lineHeight: null,
        align: null,
      }
  }
}

export const containerStyle = (style, style_num) => {
  const props = style.style.properties
  const brd = props['param_color_bordersColor']
  const brw = props['param_number_bordersSlider']
  const rd = props['param_number_cornersSlider']

  return {
    properties: _.merge(defaultsByStyle[style_num].container, {
      'alpha-brd': '1',
      'boxShadowToggleOn-shd': 'false',
      brd,
      brw,
      rd: `${rd}px`,
      shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
    }),
    propertiesSource: {
      'alpha-brd': 'value',
      'boxShadowToggleOn-shd': 'value',
      brd: 'value',
      brw: 'value',
      rd: 'value',
      shd: 'value',
    },
    groups: {},
  }
}

export const inputStyle = (style, app_style) => {
  const props = style.style.properties
  const brw = props['param_number_linesSlider']
  const aBrd = props['alpha-param_color_linesColor']
  const brd = props['param_color_linesColor']

  return {
    properties: _.merge(defaultsByStyle[app_style].input, {
      'alpha-brd': aBrd,
      brw: isInputBorderBottom(app_style) ? `0 0 ${brw}px 0` : brw,
      brd,
    }),
    propertiesSource: {
      'alpha-brd': 'value',
      brd: 'value',
      brw: 'value',
    },
    groups: {},
  }
}

export const buttonStyle = (style) => {
  const props = style.style.properties
  const aBrd = props['alpha-param_color_buttonBorderColor']
  const aBg = props['alpha-param_color_buttonColor']
  const aTxt = props['alpha-param_color_buttonTextColor']
  const brd = props['param_color_buttonBorderColor']
  const bg = props['param_color_buttonColor']
  const txt = props['param_color_buttonTextColor']
  const brw = props['param_number_buttonBorderWidth']
  const fnt = JSON.parse(props['param_font_buttonFont'] || '{}') //TODO: need to create fnt
  return {
    properties: {
      'alpha-bg': aBg,
      // 'alpha-bgh': '1',
      'alpha-brd': aBrd,
      // 'alpha-brdh': '0',
      'alpha-txt': aTxt,
      // 'alpha-txth': '1',
      bg,
      // bgh: '#000000',
      // 'boxShadowToggleOn-shd': 'false',
      brd,
      // brdh: 'color_11',
      brw,
      fnt: fnt.editorKey || 'font_8',
      // shd: "0px 1px 4px 0px rgba(0,0,0,0.6)",
      txt: txt,
      // txth: 'color_11',
    },

    propertiesSource: {
      'alpha-bg': 'value',
      'alpha-brd': 'value',
      'alpha-txt': 'value',
      bg: 'value',
      brd: 'value',
      brw: 'value',
      fnt: 'value',
      txt: 'value',
    },
    groups: {},
  }
}

export const textSpan = (text, style, label: GSLabels) => {
  const { color, font, lineHeight, align } = styles(style, label)
  const alignStyle = align ? `text-align: ${align};` : ''
  const lineHeightStyle = lineHeight ? `line-height: ${lineHeight};` : ''
  return `<span class="${font.editorKey}" style="${lineHeightStyle}${alignStyle}"><span style="font-size:${font.size}px;"><span class="${color}">${text}</span></span></span>`
}

export const textParagraph = (text, style, label: GSLabels, center = false) => {
  const { font } = styles(style, label)
  const centerStyle = center ? 'text-align: center;' : '' 
  return `<p style="font-size:${font.size}px;${centerStyle}"> ${text} </p>`
}
