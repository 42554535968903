import _ from 'lodash'
import { FormsFieldPreset, FormPlugin } from '@wix/forms-common'
import { FormPreset } from '../../../../constants/form-types'
import { ROLE_FORM, STEP_ROLE, THANK_YOU_STEP_ROLE } from '../../../../constants/roles'
import successMessageStructure from '../../../../assets/presets/hidden-message.json'
import submitButtonStructure from '../../../../assets/presets/submit-button.json'
import { fieldsStore } from '../../preset/fields/fields-store'
import { formEnhancers } from './get-subscribers-form-enhancers'
import { GSExtraData } from './get-subscribers-types'
import { textFieldEnhancers, specialFieldEnhancers } from './get-subscribers-field-enhancers'
import sideInputLabel from '../../../../assets/presets/side-input-label.json'
import {
  textSpan,
  GSLabels,
  containerStyle,
  buttonStyle,
  textParagraph,
} from './get-subscribers-style'
export const createState = (getSubscribersForm) => ({
  type: 'Container',
  skin: 'wysiwyg.common.components.statebox.viewer.skins.StateBoxStateFormSkin',
  layout: {
    x: 0,
    y: 0,
    width: getSubscribersForm.layout.width,
    height: getSubscribersForm.layout.height,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  design: {
    type: 'MediaContainerDesignData',
    background: {
      color: 'white',
      colorOpacity: 1,
      type: 'BackgroundMedia',
    },
  },
  // "mobileHintsQuery": "mobileHints-k0l4f1dr",
  componentType: 'wysiwyg.viewer.components.StateBoxFormState',
  style: {
    type: 'ComponentStyle',
    id: 'style-k0l4f1a62',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
    },
    style: {
      properties: {
        rd: '0px',
      },
      propertiesSource: {
        rd: 'value',
      },
      groups: {},
    },
    componentClassName: 'wysiwyg.viewer.components.StateBoxFormState',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.common.components.statebox.viewer.skins.StateBoxStateFormSkin',
  },
  role: STEP_ROLE,
  config: {
    title: 'pageTitle',
  },
})

export const createMultiStepBaseStructure = (
  getSubscribersForm: ComponentStructure,
  style: any,
): RawComponentStructure => ({
  type: 'Container',
  skin: 'wysiwyg.common.components.statebox.viewer.skins.StateBoxSkin',
  componentType: 'wysiwyg.viewer.components.StateBox',
  layout: {
    x: getSubscribersForm.layout.x,
    y: getSubscribersForm.layout.y,
    width: getSubscribersForm.layout.width,
    height: getSubscribersForm.layout.height,
  },
  style: {
    type: 'ComponentStyle',
    id: 'style-k0l4f17s',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
    },
    style,
    componentClassName: 'wysiwyg.viewer.components.StateBox',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.common.components.statebox.viewer.skins.StateBoxSkin',
  },
  role: ROLE_FORM,
  config: {
    preset: 'multi-step-job-application-form', // TODO
    labels: ['contacts-contacted_me', 'f32e889b-07cb-44bf-9d22-e81fc64d4b54'],
    errorMessage: 'Fill in missing fields.',
    secondsToResetForm: 3,
    formName: 'Multi Step', //TODO
    formLabelId: '24fe219b-9a92-478f-b237-5975df68f898', // check
  },
  props: {
    type: 'StateBoxProperties',
    metaData: {
      schemaVersion: '1.0',
    },
    transition: 'NoTransition',
    transDuration: 1,
  },
})

export const createSubmitButtonComponent = (
  getSubscribersForm: ComponentStructure,
  settings: GetSubscribersSettings,
  extraData: GSExtraData,
): RawComponentStructure => {
  return _.merge({}, submitButtonStructure, {
    layout: {
      width: 142,
      height: 40,
      x: 60,
      y: 52,
      ...extraData.desktopLayouts.button,
    },
    data: {
      label: settings.signupForm.buttonText || 'Subscribe Now', //TO Check
    },
    style: {
      style: buttonStyle(getSubscribersForm.style),
    },
  })
}

const createSuccessMessageComponent = (
  getSubscribersForm,
  settings: GetSubscribersSettings,
  getSubscribers: ComponentStructure,
): RawComponentStructure => {
  const title = textSpan(
    settings.signupForm.thankYouTitleText || 'Congrats!',
    getSubscribersForm.style,
    GSLabels.ThankYouTitle,
  )
  const subtitle = textSpan(
    settings.signupForm.thankYouSubtitleText ||
      'Your ‘Get Subscribers’ form works. Now go ahead and publish your site to get real subscribers.',
    getSubscribersForm.style,
    GSLabels.ThankYouSubtitle,
  )

  return _.merge({}, successMessageStructure, {
    layout: {
      width: getSubscribers.layout.width - 60,
      height: 21,
      x: 30,
      y: 20,
    },
    data: {
      text: `<div>
        ${textParagraph(title, getSubscribersForm.style, GSLabels.ThankYouTitle, true)}
        <p style="font-style: 1em;"> <p>
        ${textParagraph(subtitle, getSubscribersForm.style, GSLabels.ThankYouSubtitle, true)}
        </div>`,
    },
  })
}

const getTitles = (
  getSubscribersForm: ComponentStructure,
  settings: GetSubscribersSettings,
  extraData: GSExtraData,
) => {
  const textTitle =
    settings.signupForm.showTitle && (settings.signupForm.titleText || 'Join our mailing list')
  const textSubTitle =
    settings.signupForm.showSubtitle && (settings.signupForm.subtitleText || 'Never miss an update')

  if (!textTitle && !textSubTitle) {
    return []
  }

  const { subTitle, title } = extraData.desktopLayouts
  const height = subTitle ? subTitle.y + subTitle.height - title.height : title.height

  const sameLine = settings.signupForm.layout === 0
  const width = sameLine && subTitle ? subTitle.x + subTitle.width - title.x : title.width
  const extraSpace = (sameLine ? ' ' : '')

  const titleSpan = textSpan(textTitle, getSubscribersForm.style, GSLabels.Title)
  const subtitleSpan =
    textSubTitle && textSpan(extraSpace + textSubTitle, getSubscribersForm.style, GSLabels.Subtitle)

  const titlesComponent = _.merge({}, sideInputLabel, {
    data: {
      text: `<div>
         ${textParagraph(
           `${titleSpan}${sameLine && subtitleSpan ? subtitleSpan : ''}`,
           getSubscribersForm.style,
           GSLabels.Title,
         )}
         ${
           !sameLine && subtitleSpan
             ? textParagraph(subtitleSpan, getSubscribersForm.style, GSLabels.Title)
             : ''
         }
        </div>`,
    },
    props: {
      minHeight: null,
    },
    layout: _.merge({}, extraData.desktopLayouts.title, { height, width }),
  })

  return [titlesComponent]
}

const createBaseField = (fieldData: GetSubscribersFormField) => {
  switch (fieldData.name) {
    case 'name':
      return fieldsStore.allFieldsData[FormsFieldPreset.FIRST_NAME]
    case 'phone':
      return fieldsStore.allFieldsData[FormsFieldPreset.PHONE]
    case 'email':
      return fieldsStore.allFieldsData[FormsFieldPreset.MAIN_EMAIL]
    case 'agree':
      return fieldsStore.allFieldsData[FormsFieldPreset.AGREE_TERMS]
    default:
      return fieldsStore.allFieldsData[FormsFieldPreset.GENERAL_TEXT]
  }
}

const createFieldData = (settings: GetSubscribersSettings): GetSubscribersFormField[] => {
  const fields = []
  if (settings.generalSettings.collectName) {
    const nameField = {
      name: 'name',
      required: settings.generalSettings.nameRequired,
      displayLabel: settings.generalSettings.namePlaceholder || 'Name',
    }
    fields.push(nameField)
  }

  if (settings.generalSettings.collectPhone) {
    const phoneField = {
      name: 'phone',
      required: settings.generalSettings.phoneRequired,
      displayLabel: settings.generalSettings.phonePlaceholder || 'Phone',
    }
    fields.push(phoneField)
  }
  if (settings.gdpr.requireConsent) {
    const agreeTerms = {
      name: 'agree',
      required: true,
      displayLabel: settings.gdpr.consentMessage,
    }
    fields.push(agreeTerms)
  }

  const emailField = {
    name: 'email',
    required: true,
    displayLabel: settings.generalSettings.emailPlaceholder || 'Email Address',
  }

  fields.push(emailField)

  return fields
}

const createFieldsFromSettings = (
  getSubscribersForm: ComponentStructure,
  settings: GetSubscribersSettings,
  extraData: GSExtraData,
) => {
  const fieldsData = createFieldData(settings)

  return _.map(fieldsData, (field) => {
    const baseField = createBaseField(field).fieldStructure() as RawComponentStructure
    const fieldEnhancers = _.includes(['email', 'phone', 'name'], field.name)
      ? textFieldEnhancers
      : specialFieldEnhancers

    const convertedFieldStructure = fieldEnhancers.reduce<RawComponentStructure>(
      (previousFieldStructure, enhancer) =>
        enhancer({
          getSubscribersForm,
          settings,
          fieldData: field,
          convertedField: previousFieldStructure,
          extraData,
        }),
      baseField,
    )
    return convertedFieldStructure
  })
}

export const convertGetSubscribersFormToWixForms = (
  getSubscribersForm: ComponentStructure,
  settings: GetSubscribersSettings,
  extraData: GSExtraData,
): RawComponentStructure => {
  const fields = createFieldsFromSettings(getSubscribersForm, settings, extraData)

  const components: RawComponentStructure[] = [
    ...getTitles(getSubscribersForm, settings, extraData),
    ...fields,
    createSubmitButtonComponent(getSubscribersForm, settings, extraData), //TODO check stylable
  ]

  const mainStep = _.merge({}, createState(getSubscribersForm), {
    components,
  })

  const thankYouStep = _.merge({}, createState(getSubscribersForm), {
    components: [createSuccessMessageComponent(getSubscribersForm, settings, getSubscribersForm)],
    role: THANK_YOU_STEP_ROLE,
    config: {
      title: 'Thank You Message', //TODO: translate
    },
  })

  const style = containerStyle(getSubscribersForm.style, settings.style.appStyle)
  const baseStructure = _.merge({}, createMultiStepBaseStructure(getSubscribersForm, style), {
    components: [mainStep, thankYouStep],
    config: {
      preset: FormPreset.GET_SUBSCRIBERS,
      formName: settings.signupForm.titleText || 'Get Subscribers',
      msid: extraData.msid,
      plugins: [
        {
          id: FormPlugin.GET_SUBSCRIBERS,
        },
        {
          id: FormPlugin.MULTI_STEP_FORM,
        },
      ],
    },
  })

  const convertedStructure = formEnhancers.reduce<RawComponentStructure>(
    (previousStructure, enhancer) => enhancer(settings, previousStructure, extraData),
    baseStructure,
  )

  return convertedStructure
}
