import _ from 'lodash'
import { GetSubscribersFieldEnhancer, GSExtraData, GSInputLayout, GSLayout } from './get-subscribers-types'
import { GSInputFields } from './get-subscribers-utils'

const getInputLayout = ({ input, label }: GSInputLayout) => {
  const y = label?.y || input.y
  const x = label?.x || input.x
  const width = input.width
  const height = label ? input.y + input.height - label.y : input.height

  return {
    layout: { x, y, height, width },
    inputHeight: input?.height,
    labelMargin: 7,
  }
}

const getAgreeLayout = ({ checkbox, text }: {checkbox: GSLayout, text: GSLayout}) => {
  const width = (text ? text.x + text.width - checkbox.x : checkbox.width) + 5
  
  return {
    ...checkbox,
    width
  }
}

const getFieldLayoutAndHeight = (
  fieldData: GetSubscribersFormField,
  { desktopLayouts }: GSExtraData,
) => {
  switch (fieldData.name) {
    case 'name':
      return getInputLayout(desktopLayouts.name)
    case 'phone':
      return getInputLayout(desktopLayouts.phoneNumber)
    case 'email':
      return getInputLayout(desktopLayouts.email)
    case 'agree':
      return { layout: getAgreeLayout(desktopLayouts.consent) }
    default:
      return { layout: {}, inputHeight: null, labelMargin: null }
  }
}

export const updateDesktopLayout: GetSubscribersFieldEnhancer = ({
  fieldData,
  convertedField,
  extraData,
}): RawComponentStructure => {
  const { layout, inputHeight, labelMargin } = getFieldLayoutAndHeight(fieldData, extraData)
  convertedField.layout = _.merge(convertedField.layout, layout)
  if (_.includes(GSInputFields, fieldData.name)) {
    convertedField.props.inputHeight = inputHeight || 30
    convertedField.props.labelMargin = labelMargin || 7
  }
  return convertedField
}
