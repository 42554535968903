import _ from 'lodash'
import { updateDesktopLayout } from './get-subscribers-layout'
import { GetSubscribersFieldEnhancer } from './get-subscribers-types'
import { isSkinWithFieldTitlesGS } from './get-subscribers-utils'
import { inputStyle } from './get-subscribers-style'

export const updateConfig: GetSubscribersFieldEnhancer = ({ convertedField }) => {
  convertedField.config = { ...(convertedField as any).connectionConfig }
  delete (convertedField as any).connectionConfig
  return convertedField
}

export const updateCrmLabel: GetSubscribersFieldEnhancer = ({ fieldData, convertedField }) => {
  convertedField.config.crmLabel = fieldData.displayLabel.substring(0, 25).trim() || fieldData.name
  return convertedField
}

const updateLabel: GetSubscribersFieldEnhancer = ({ settings, fieldData, convertedField }) => {
  if (isSkinWithFieldTitlesGS(settings)) {
    convertedField.data.placeholder = ''
    convertedField.props.placeholder = ''
    convertedField.data.label = fieldData.displayLabel
  }
  return convertedField
}

export const updatePlaceholder: GetSubscribersFieldEnhancer = ({
  settings,
  fieldData,
  convertedField,
}) => {
  const content = `${fieldData.displayLabel}${fieldData.required ? ' *' : ''}`

  if (!isSkinWithFieldTitlesGS(settings)) {
    convertedField.data.label = ''
    convertedField.data.placeholder = content
    convertedField.props.placeholder = content
  }
  return convertedField
}

const updateAgreeTerms: GetSubscribersFieldEnhancer = ({ settings, fieldData, convertedField }) => {
  if (fieldData.name !== 'agree') {
    return convertedField
  }
  convertedField.data.checked = false
  convertedField.data.label = settings.gdpr.consentMessage || 'some basic text'

  if (settings.gdpr.isPrivacyPolicyLinkEnabled) {
    convertedField.data.link = {
      type: 'ExternalLink',
      target: '_blank',
      url:
        settings.gdpr.policyLinkUrl || `http://www.${settings.gdpr.policyLinkText || 'google'}.com`,
    }
    settings.gdpr.policyLinkUrl || `www.${settings.gdpr.policyLinkText || 'google'}.com`
    convertedField.data.linkLabel = settings.gdpr.policyLinkText
  }

  return convertedField
}

const updateRequired: GetSubscribersFieldEnhancer = ({ fieldData, convertedField }) => {
  convertedField.props.required = fieldData.required
  return convertedField
}

const paddingEnhancer: GetSubscribersFieldEnhancer = ({ convertedField }) => {
  return _.merge({}, convertedField, { props: { textPadding: 5 } })
}

const fieldStyle: GetSubscribersFieldEnhancer = ({
  convertedField,
  getSubscribersForm,
  settings,
}) => {
  return _.merge({}, convertedField, {
    style: { style: inputStyle(getSubscribersForm.style, settings.style.appStyle) },
  })
}

const commonEnhancers: GetSubscribersFieldEnhancer[] = [
  paddingEnhancer,
  updateConfig,
  updateCrmLabel,
  updateDesktopLayout,
]

export const textFieldEnhancers: GetSubscribersFieldEnhancer[] = [
  ...commonEnhancers,
  updatePlaceholder,
  updateRequired,
  updateLabel,
  fieldStyle,
]

export const specialFieldEnhancers: GetSubscribersFieldEnhancer[] = [
  ...commonEnhancers,
  updateAgreeTerms,
]
