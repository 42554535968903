import {
  ActionTriggersServer,
  Rule as ActionTriggersRule
} from '@wix/ambassador-action-triggers-server/http'
import { WixContactsWebapp } from '@wix/ambassador-wix-contacts-webapp/http'
import {
  ExternalFieldMapping, MigrateFieldsRequest,
  MigrateFieldsResponse
} from '@wix/ambassador-wix-contacts-webapp/types'
import { EmailResponse, UserEmail } from '@wix/ambassador-wix-form-builder-web/http'
import Experiments from '@wix/wix-experiments'
import * as axios from 'axios'
import _ from 'lodash'
import { FORMS_APP_DEF_ID } from '../../constants'
import { CustomField, CustomFieldResponse } from '../../constants/field-types'
import { PremiumRestriction } from '../../constants/premium'
import { ConvertEmailRequest, ConvertEmailResponse } from '../../types/domain-types'
import { parseInstance } from '../../utils/utils'
import { ASCEND_PLAN, MAP_PRODUCT_ID_TO_ASCEND_PLAN } from './constants/ascend'
import {
  FORMS_SERVERLESS, PLATFORMIZED_PAYMENTS_URL,
  PREMIUM_STORE_URL
} from './constants/external-endpoints'
import { FormsServiceAmbassadorClient } from './formsServiceAmbassadorClient'


export const Method = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
}

export const FEATURES = {
  CUSTOM_STEPS: 'custom_steps',
  SUBMISSIONS_PER_MONTH: 'submissions_per_month',
  CUSTOM_FIELDS: 'custom_fields',
  CUSTOMIZABLE_FORMS: 'customizable_forms',
  ACCEPT_PAYMENTS: 'accept_payments_on_form',
  UPLOAD_FIELD: 'file_upload_field',
  DOWNLOAD_FILE: 'file_downloads',
  SIGNATURE_FIELD: 'signature_field',
  EMAILS: 'max_emails_on_form',
  RULES: 'forms_conditional_forms',
}

const ASCEND_PRODUCT_ID = '73988963-5f5f-4f61-b6a1-fd004df31b00'
const callerId = { 'X-Wix-Client-Artifact-Id': 'wix-form-builder' }
const CONTACT_FORM_ACTIVITY = 'contact/contact-form'
const WIX_FORMS_ACTIVITY = 'form/form'

const getService = (appInstance) => ({
  contacts: () => {
    const contactsServices = WixContactsWebapp('/wix-contacts-webapp')
    return {
      labels: contactsServices.ContactsLabelsService()({ Authorization: appInstance, ...callerId }),
      schema: contactsServices.ContactsSchemaService()({ Authorization: appInstance, ...callerId }),
    }
  },
  actionTriggers: () => {
    const url = '/_api/action-triggers-server'
    return {
      triggers: ActionTriggersServer(url).ActionTriggersIntegratorService()({
        Authorization: appInstance,
        ...callerId,
      }),
      backoffice: ActionTriggersServer(url).AutomationsBackofficeService()({
        Authorization: appInstance,
        ...callerId,
      }),
    }
  },
})

export interface ContactsLabel {
  id: string
  type: string
  name: string
}

export default class RemoteApi {
  private boundEditorSDK: BoundEditorSDK
  private experiments: Experiments
  private ravenInstance
  private formServiceClient: FormsServiceAmbassadorClient

  constructor({ boundEditorSDK, experiments, ravenInstance, formServiceClient }) {
    this.boundEditorSDK = boundEditorSDK
    this.experiments = experiments
    this.ravenInstance = ravenInstance
    this.formServiceClient = formServiceClient
  }

  private async _getAppInstance() {
    return this.boundEditorSDK.info.getAppInstance()
  }

  public async getFormsRules() {
    const hasConditions = (rule) => {
      const conditions = _.get(rule, 'trigger.condition.allCondition.conditions')
      return conditions && conditions.length
    }
    const appInstance = await this._getAppInstance()

    const res = await getService(appInstance)
      .actionTriggers()
      .triggers.searchRules({ eventType: [CONTACT_FORM_ACTIVITY, WIX_FORMS_ACTIVITY] })

    const rules = res.rules
    const contactFormRules = rules.filter(
      (role) => _.get(role, 'trigger.activityType') === CONTACT_FORM_ACTIVITY,
    )
    const wixFormsRules = rules.filter(
      (role) => _.get(role, 'trigger.activityType') === WIX_FORMS_ACTIVITY,
    )
    const wixFormsAllForms = wixFormsRules.filter((rule) => !hasConditions(rule))
    const wixFormsSpecificForms = wixFormsRules.filter((rule) => hasConditions(rule))
    return { contactFormRules, wixFormsAllForms, wixFormsSpecificForms }
  }

  public async migrateRule(ruleId: string, formIds?: string[], schema?: string) {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .actionTriggers()
      .backoffice.migrateForms({ formIds, ruleId, schema })
  }

  public async disableRule(rule: ActionTriggersRule) {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .actionTriggers()
      .triggers.updateRule({ rule: { ...rule, status: 'suspended' } })
  }

  public async getEmailsById(emailIds = []): Promise<EmailResponse[]> {
    const emailsResponse = await this.formServiceClient.getEmailsByEmailIds(emailIds)

    return emailIds.map((emailId) => {
      const emailFromResponse = _.find(emailsResponse.emails, { emailId })
      return emailFromResponse || { emailId, email: '' }
    })
  }

  public async getSiteUsersData(): Promise<UserEmail[]> {
    return (await this.formServiceClient.getSiteUsers()).emails
  }

  public async insertEmail(email) {
    return this.formServiceClient.addEmail(email)
  }

  public async createTag(tagName) {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .contacts()
      .labels.create({ details: { name: tagName } })
      .then((res) => res.label)
  }

  public async updateTag(tagId, newName) {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .contacts()
      .labels.update({ id: tagId, details: { name: newName } })
      .then((res) => res.label)
  }

  public async getLabels(): Promise<ContactsLabel[]> {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .contacts()
      .labels.list({})
      .then((data) => {
        return _.map(data.labels, (label) => ({
          id: label.id,
          type: label.type,
          name: label.details.name,
        }))
      })
  }

  public async getCustomFields(): Promise<CustomFieldResponse[]> {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .contacts()
      .schema.listFields({})
      .then(({ fields }) =>
        fields
          .filter((field) => field.fieldType === 'USER_DEFINED')
          .map<CustomFieldResponse>((field) => ({
            id: field.id,
            name: field.details.name,
            // TODO: use DataType instead of customFields
            fieldType: field.details.dataType as any,
          })),
      )
      .catch(() => [])
  }

  public async migrateFields(request: MigrateFieldsRequest): Promise<ExternalFieldMapping[]> {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .contacts()
      .schema.migrateFields(request)
      .then((res: MigrateFieldsResponse) => {
        return res.migrated
      })
      .catch(() => [])
  }

  public async createCustomField(field: CustomField) {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .contacts()
      .schema.createField({ details: { name: field.name, dataType: field.fieldType as any } })
      .then((res) => res.field)
  }

  public async updateCustomFieldName({
    id,
    newName,
  }: {
    id: string
    newName: string
  }): Promise<void> {
    const appInstance = await this._getAppInstance()
    await getService(appInstance)
      .contacts()
      .schema.updateField({
        id,
        details: {
          name: newName,
        },
      })
  }

  public async publishSite(data) {
    return await this.formServiceClient.publishSite(data)
  }

  public addContactFormMapping(data) {
    return this.formServiceClient.addContactFormMapping(data)
  }

  public async editDraft(form) {
    try {
      return await this.formServiceClient.editDraft(form)
    } catch {
      return null
    }
  }

  public async getPremiumRestrictions(): Promise<{
    restrictions: PremiumRestriction
    currentAscendPlan: string
  }> {
    return this._platformizedRequest({
      url: FORMS_SERVERLESS.URL,
      endpoint: FORMS_SERVERLESS.ENDPOINT.RESTRICTIONS,
      method: Method.GET,
    })
  }

  public async getCurrentAscendPlan(): Promise<{ ascendPlan: string; isTopPremium: boolean }> {
    const msid = await this.boundEditorSDK.info.getMetaSiteId()
    const endpoint = `offering/${ASCEND_PRODUCT_ID}?msid=${msid}`
    let currentSubscriptionInfo

    try {
      const request = await this._platformizedRequest({
        url: PREMIUM_STORE_URL,
        endpoint,
        method: Method.GET,
      })
      currentSubscriptionInfo = _.get(request, 'currentSubscriptionInfo')
    } catch {
      currentSubscriptionInfo = {}
    }

    const productId = _.get(currentSubscriptionInfo, 'productId')

    const currentPlan = MAP_PRODUCT_ID_TO_ASCEND_PLAN[productId] || ASCEND_PLAN.FREE

    return { ascendPlan: currentPlan, isTopPremium: currentPlan === ASCEND_PLAN.UNLIMITED }
  }

  public async getConnectedPayments() {
    const appInstance = await this._getAppInstance()
    const instanceId = parseInstance(appInstance).instanceId
    const endpoint = `accounts/${FORMS_APP_DEF_ID}:${instanceId}/payment-methods`

    return this._platformizedRequest({
      url: PLATFORMIZED_PAYMENTS_URL,
      endpoint,
      method: Method.GET,
    })
  }

  public async convertContactFormEmails(
    convertContactFormEmailsRequest: ConvertEmailRequest,
  ): Promise<ConvertEmailResponse> {
    return this.formServiceClient.convertContactFormEmails(convertContactFormEmailsRequest)
  }

  private async _platformizedRequest({
    url,
    endpoint,
    method,
    data = undefined,
  }: {
    url: string
    endpoint: string
    method: string
    data?: any
  }) {
    try {
      const isTemplate = !(await this.boundEditorSDK.info.isSiteSaved())

      if (isTemplate) {
        return Promise.resolve()
      }

      const appInstance = await this._getAppInstance()

      const response = await axios.request({
        headers: { Authorization: appInstance, ...callerId },
        method,
        url: `${url}/${endpoint}`,
        data,
      })

      return _.get(response, 'data')
    } catch (err) {
      this.ravenInstance.setExtraContext({ url, endpoint, method, data })
      throw err
    }
  }
}
